a {
  color: inherit;
}

footer svg {
  padding-right: 4px;
}
html {
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none;
}

.bg-dark {
  display: flex;
  flex-direction: column;;
}